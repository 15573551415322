//cookie一時対策
window.addEventListener('load' , function(){
  deleteCookie('utag_main','/','u-can.co.jp');
  deleteCookie('s_sq','/','u-can.co.jp');
  deleteCookie('s_sq','/','lbl.u-can.co.jp');
  deleteCookie('_bcvm_vrid_734322833284087337','/','.u-can.co.jp');
  deleteCookie('_bcvm_vid_734322833284087337','/','.u-can.co.jp');
}, false);

function deleteCookie(name,path,domain) {
  var exp =new Date();
  exp.setTime(exp.getTime() - 1);
  var cookie = escape(name) + "=xxx;" + "expires=" + exp.toUTCString() + ((path==null)?"":";path="+path) + ((domain==null)?"":";domain="+domain);
  document.cookie = cookie;
}